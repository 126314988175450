/*==================== Variables ====================*/
/* Constant */
const DURATION = {
  FAST: 150,
  FASTER: 200,
  STD: 300,
  NOR: 400,
  SLOWER: 500,
  SLOW: 600
}

/*==================== Initialize ====================*/
/**
 * 【程式進入點】
 * 項目名稱: Includes 載入器
 *
 * 說明:
 *    監聽所有 includes 的載入、並作同步處理，
 *    下載完成後進入 listenImagesLoading，
 *    監聽完成即進入 this.callback。
 *
 * 必填項目: includesList
 */
includesLoader({
  // filesRootPath: "./includes/",
  // filesExtension: ".html",
  callback: mainFunc,
  includesList: [
    {
      target: '.page-header',
      // rootPath: "./includes/",
      fileName: '_page-header'
      // extension: ".html",
      // callback: function() {}
    },
    {
      target: '.page-footer',
      fileName: '_page-footer'
    }
  ]
})

/*==================================================*\
        Main Function
\*==================================================*/
function mainFunc() {
  console.clear();
  /* window 事件: Resize & scroll */
  windowEvents()

  // 手機版選單
  $('.hamberger').on('click', function() {
    $(this).toggleClass('open')
  })
  $('.page-footer .webBox').on('click', function() {
    $('.page-footer .webBox .aBox').slideToggle(200)
  })

  // 首頁-授權經銷商 SLICK
  $('.sellerBox ._slick').slick({
    infinite: false,
    dots: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 1
        }
      }
    ]
  })

  // 睡眠障礙 Page
  if ($('.sleep-page').length > 0) {
    const $sleepTag = $('.tagBox > a')
    const $productContentChildren = $('.sleep-page .productContent').children()
    const SLEEP_TAG_CLASS = 'selected'
    const SHOW_PRODUCT_CLASS = 'show'

    // Sleep Tag
    $sleepTag.on('click', function() {
      const $this = $(this)
      const TAG_IDX = $this.index()

      if (TAG_IDX >= $productContentChildren.length) return

      $sleepTag.removeClass(SLEEP_TAG_CLASS)
      $this.addClass(SLEEP_TAG_CLASS)

      $productContentChildren.removeClass(SHOW_PRODUCT_CLASS)
      $productContentChildren.eq(TAG_IDX).addClass(SHOW_PRODUCT_CLASS)
    })

    // Sleep Breath subTag
    const $sleepBreathSubTag = $('.sleepBreath .btnBox > a')
    const $subProductContentChildren = $('.sleepBreath .productBox').children()

    $sleepBreathSubTag.on('click', function() {
      const $this = $(this)
      const SUB_TAG_IDX = $this.index()

      if (SUB_TAG_IDX >= $subProductContentChildren.length) return

      $sleepBreathSubTag.removeClass(SLEEP_TAG_CLASS)
      $this.addClass(SLEEP_TAG_CLASS)

      $subProductContentChildren.removeClass(SHOW_PRODUCT_CLASS)
      $subProductContentChildren.eq(SUB_TAG_IDX).addClass(SHOW_PRODUCT_CLASS)
    })

    // Showing Preset
    $sleepTag.eq(0).trigger('click')
    $sleepBreathSubTag.eq(0).trigger('click')

    // Survey 
    $('.survey').lightbox({
      callback: function() {

        const $survey = $('.surveyWrap .survey');
        const scoreLimit = [2, 2, 1];
        let score;
        let analysisAns = [false, false, false];

        $('label.radioWrap').on('click', function(evt) {
          evt.preventDefault();
          $(this).find('input[type="radio"]').prop('checked', true);
          
          // 分數計算
          score = [0, 0, 0];
          $.each($survey, function(surveyIdx, surveyEl) {
            $.each($(surveyEl).find('input[type="radio"]'), function(idx, el) {
              const $this = $(el);
              if ($this.prop('checked')) {
                score[surveyIdx] += parseInt($this.val());
              }
            });
          })

          // 分析
          $.each(score, (idx, val) => {
            analysisAns[idx] = ((val >= scoreLimit[idx]) ? true : false);
            $survey.eq(idx).find('.partScoreCheck .ans').text(analysisAns[idx] ? '是' : '否');
          });
        });

        picturefill();
      }
    })
  }

  // 呼吸治療 Page
  if ($('.breathe-page').length > 0) {
    const $solutionTab = $('.solution .tab')
    $solutionTab.on('click', function(evt) {
      evt.preventDefault()

      const thisIdx = parseInt($(this).data('item'))

      $('.solution .item').hide()
      $('.solution .item-' + thisIdx).show()

      $solutionTab.removeClass('active')
      $solutionTab.eq(thisIdx - 1).addClass('active')
    })

    $('.solution .tab')
      .eq(0)
      .trigger('click')
  }

  // 進站人員分類lightbox
  var $lb = $('.lb'),
      $lbbox = $('.lbbox');

  var lb_data,
      lbname = 'audience';

  TweenMax.set($('.lb'),{autoAlpha:0});

  function lbOpen(lb_data){
    var lbAni = new TimelineMax();
        lbAni.to($('.lb[data-lb="'+lb_data+'"]') , .5 ,{css:{zIndex:1000}} , '-=.5')
             .to($('.lb[data-lb="'+lb_data+'"]') , .5 ,{autoAlpha:1 ,ease:Linear.easeNone}); 
  }

  function lbClose(){
      var lbCloseAni = new TimelineMax();
          lbCloseAni.to($('.lb') , .5 ,{autoAlpha:0 ,ease:Linear.easeNone})
                    .to($('.lb') , .5 ,{css:{zIndex:-1}});                 
  }

  if($('#index-page').length && $.cookie('audience')==null){
    lbOpen(lbname);
    
    $('.lb_audience .btnpeo').click(function(e){
      var href = $(this).attr('href');
      if(href!='javascript:;'){
          e.preventDefault();
          setTimeout(function(){
              location.href = href;
          },500);
          
          $.cookie('audience', 2, { path: '/' });
      }
      else{
          $.cookie('audience', 1, { path: '/' });
      }
    });
  }
  $('.libtn').click(function(){
        var lbname = $(this).data('lb');

        lbOpen(lbname);

        return false;
  }); 
  
  $('.lb .btn_x ,.lb .btnClose').click(lbClose);
  
  



  /*---------- news.json 載入 ----------*/
  if ($('#app').length > 0) {
    // Get Page Parameter
    let para = location.href.split('?')[1]
    let pageIdx
    if (!!para) {
      para = para.split('=')

      if (para[0] === 'id') {
        pageIdx = parseInt(para[1])
      }
    }

    // AJAX GET JSON
    const newsDataUrl = './data/news.json'
    let newsData

    $.ajax({
      url: newsDataUrl,
      contentType: 'application/json',
      dataType: 'json',
      data: '{}',
      tpye: 'GET',
      complete: function(res) {
        newsData = JSON.parse(res.responseText)
        const NEWS_DATA_LENGTH = newsData.length
        if ($('.news-page').length > 0) {
          $.each(newsData, function(idx, el) {
            if (el.id === pageIdx) {
              newsData = el
            }
          })

          if ($('.news-page').length > 0 && pageIdx >= NEWS_DATA_LENGTH) {
            location.replace('./index.html')
          }
        }

        vueBuilding(newsData)
      },
      error: function() {
        alert('Download Fail!')
      }
    })

    /*========== Vue Setting ==========*/
    function vueBuilding(newsData) {
      new Vue({
        el: '#app',
        data: {
          news: newsData
        },
        mounted() {
          // 首頁-最新消息 SLICK
          if ($('.index-page').length > 0) {
            $('.news ._slick').slick({
              dots: true,
              infinite: false,
              speed: 300,
              slidesToShow: 3,
              slidesToScroll: 1,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1
                  }
                }
              ]
            })

            $('.scrollDown, .kvBtn').on('click', () => {
              $('html, body').animate({
                scrollTop: $('.sleep').offset().top - $('.page-header').height()
              }, 400);
            });
          }

          if ($('.news-page').length > 0) {
            $('body').imagesLoaded({ background: true }).always(() => {
              newsPageArticlePaddingTopSetting();
            })
          }

          removeLoading();
        },
        // for news.html 的 methods
        methods: {
          paraClass(para) {
            return {
              title: typeof para === 'object' ? true : false
            }
          },
          paraText(para) {
            if (typeof para === 'object') {
              return para.title
            } else {
              return para
            }
          }
        }
      })
    }
  } else {
    removeLoading();
  }

  $('.goTop').on('click', function() {
    $('html, body').animate(
      {
        scrollTop: 0
      },
      DURATION.STD
    )
  })

  /* 收掉 .page-loading */
  // transitionThenRemove({
  //   dom: $('.page-loading'),
  //   duration: DURATION.STD,
  //   callback: () => {
  //     console.log('%cBuild Completed!', logSafeStyle);
  //   }
  // })

  function removeLoading() {
    transitionThenRemove({
      dom: $('.page-loading'),
      duration: DURATION.STD,
      callback: () => {
        kvAni.play();
        console.log('%cBuild Completed!', logSafeStyle);
      }
    })
  }
  // if($(".op-lb").length>0){
  //   $(".op-lb").addClass('_show');

  //   $(".op-lb .close").on('click', function(){
  //     $(".op-lb").fadeOut();
  //   })
  // }
}

let aniList;
const normalAniTimes = 1;
const gapTimes = 0.15;

const kvAni = new TimelineMax({ paused: true });
const sleepAni = new TimelineMax({ paused: true });
const lifeAni = new TimelineMax({ paused: true });
const newsAni = new TimelineMax({ paused: true });
const contactAni = new TimelineMax({ paused: true });

aniList = [kvAni, sleepAni, lifeAni, newsAni, contactAni];

if ($('#index-page').length > 0) {
  kvAni
    .from('.kv', normalAniTimes, { opacity: 0 })
    .staggerFrom('.kv .summary > *', normalAniTimes, { opacity: 0, x: 50 }, gapTimes, `-=${normalAniTimes / 2}`)
  sleepAni
    .from('.sleep', normalAniTimes, { opacity: 0 })
    .staggerFrom('.sleep .summary > *', normalAniTimes, { opacity: 0, x: -50 }, gapTimes, `-=${normalAniTimes / 2}`)
  lifeAni
    .from('.life', normalAniTimes, { opacity: 0 })
    .staggerFrom('.life .summary > *', normalAniTimes, { opacity: 0, x: 50 }, gapTimes, `-=${normalAniTimes / 2}`)
  // newsAni
  //   .from('.news', normalAniTimes, { opacity: 0 })
  // contactAni
  //   .from('.contact', normalAniTimes, { opacity: 0 })
}

/*==================================================*\
        window Events
\*==================================================*/
let isFirstTime = true
function windowEvents() {
  // Window Resize
  $win.on('resize', _resize).resize()
  // Window Scroll
  $win.on('scroll', _scroll).scroll()
}

/*========== Window Resize ==========*/
const sectionList = ['kv', 'sleep', 'life', 'news', 'contact'];
let sectionOffsets = [];
function _resize() {
  getSize()

  if($('#index-page').length > 0) {
    sectionOffsets = [];

    $.each(sectionList, (idx, val) => {
      sectionOffsets.push(Math.floor($(`.${sectionList[idx]}`).offset().top));
    });
    console.log(sectionOffsets);
  }

  if ($('.news-page').length > 0) {
    newsPageArticlePaddingTopSetting();
  }
}

let headerH;
function getSize() {
  winW = $win.width()
  winH = $win.height()
  headerH = $('.page-header').height();
}

/*========== Window Scroll ==========*/
let nowPos = {};
function _scroll() {
  getPos();

  if($('#index-page').length > 0) {
    indexScrollAnimatePlay();
  }
}

function getPos() {
  nowPos = {
    x: $win.scrollLeft(),
    y: $win.scrollTop(),
    realTop: $win.scrollTop() + headerH,
    bottom: $win.scrollTop() + winH - 20
  }
}

function indexScrollAnimatePlay() {
  $.each(sectionOffsets, (idx, val) => {
    if (nowPos.bottom >= val && idx !== 0) {      
      aniList[idx].play();
    }
  });
}

/*==================================================*\
        Library
\*==================================================*/
/* 斷點偵測 (for window resize) */
let preBP = { minimum: -1 }
function detectiveBreakpoint(breakpoint) {
  let nowBP = breakpoint[0]

  $.each(breakpoint, (idx, obj) => {
    const objName = Object.getOwnPropertyNames(obj)[0]
    const val = obj[objName]

    if (winW > val && val > nowBP[Object.getOwnPropertyNames(nowBP)[0]]) {
      nowBP = obj
    }
  })

  if (
    Object.getOwnPropertyNames(nowBP)[0] ===
    Object.getOwnPropertyNames(preBP)[0]
  ) {
    return false
  }

  // 執行 Media Query
  mediaQuery(nowBP)
}

// 執行 Media Query
function mediaQuery(nowBP) {
  const nowBPName = Object.getOwnPropertyNames(nowBP)[0]
  console.log(`Breakpoint {${nowBPName}: ${nowBP[nowBPName]}}`)

  // 執行該斷點 Media Query
  if (!nowBP.hasOwnProperty('mq')) {
    throw new Error(`此斷點(↑)尚未設定 Media Query 之屬性 "mq"(function)`)
  } else if (typeof nowBP.mq !== 'function') {
    throw new Error(`此中斷點之 Media Query 型別並非 "function`)
  } else {
    nowBP.mq()
  }

  preBP = nowBP
}

function newsPageArticlePaddingTopSetting() {
  if (winW < 1024) return;

  const KV_HEIGHT = Math.round($('.kv').outerHeight());
  const OUTLINE_HEIGHT = Math.round($('.outline').outerHeight());
  const KVAndOutlineOffset = Math.abs(KV_HEIGHT - OUTLINE_HEIGHT);
  const OUTLINE_AND_KV_TOLERANCE = 85;
  const GAP = 30;

  let total;
  if ((OUTLINE_HEIGHT + OUTLINE_AND_KV_TOLERANCE - KV_HEIGHT <= 0)) {
    total = 90;
  } else {
    total = KVAndOutlineOffset + OUTLINE_AND_KV_TOLERANCE + GAP;
  }

  $('.content article').css({ paddingTop: total });
}